<template>
  <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
    <div class="container-fluid shadow-sm">
      <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
        <a
          @click="back"
          class="btn p-0 mr-md-4 text-light h5 mb-0"
        >
          <i class="fas fa-arrow-left"></i>
        </a>
        <span
          class="
            navbar-brand
            bg-transparent
            rounded
            p-0
            text-center
            mr-0 mr-md-5
          "
        >
          <span class="h6 font-weight-bold text-truncate"
            >Pelanggan Grup - Edit</span
          >
        </span>
        <a href="javascript:void(0);" class="btn px-0 d-block d-md-none">
          <i class="fa fa-home text-main"></i>
        </a>
      </nav>
    </div>
    <!-- .container -->
  </header>

  <div class="container-fluid mt-header">
    <div class="row" id="body-sidemenu">
      <!-- MAIN -->
      <div id="main-content" class="col with-fixed-sidebar">
        <div class="row d-none">
          <div class="col-12">
            <div
              class="
                alert alert-danger
                bg-light
                mt-3
                mb-0
                alert-dismissible
                fade
                show
              "
              role="alert"
            >
              <button type="button" class="close" data-dismiss="alert">
                &times;
              </button>
              <strong>Alert!</strong> You should
              <a
                href="javascript:void(0);"
                class="alert-link"
                title="Click to go to this message"
                >read this message</a
              >.
            </div>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->

        <div class="row mt-4 mb-5">
          <div class="col-12">
            <Notif />
            <Form 
              @submit="edit"
              :validation-schema="schema"
              v-slot="{ errors }"
              method="POST" class="mb-3 needs-validation">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="nama"
                      >Nama Grup Pelanggan:
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      type="text"
                      class="form-control"
                      v-model="state.form.name"
                      placeholder="Masukkan nama..."
                      name="name"
                      :class="{'is-invalid': errors.name }"
                    />
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">{{ errors.name }}</div>
                  </div>
                </div>
                <!-- .col-* -->
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="deskripsi">Deskripsi:</label>
                    <Field type="hidden" v-model="state.form.desc" name="desc" />
                    <textarea
                      class="form-control"
                      :class="{'is-invalid': errors.desc }"
                      rows="2"
                      name="desc"
                      v-model="state.form.desc"
                      placeholder="Masukkan deskripsi..."
                    ></textarea
                    >
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">{{ errors.desc }}</div>
                  </div>
                </div>
                <!-- .col-* -->
              </div>
              <!-- .row -->

              <hr class="no-side-margin" />

              <div class="row mt-3">
                <div class="col text-center">
                  <button type="submit" class="btn btn-main" title="Simpan" :disabled="state.loading">
                    <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="state.loading"></span>
                    <i class="fa fa-save mr-2" v-else></i> Simpan
                  </button>
                  <button
                    type="reset"
                    class="btn btn-light ml-1"
                    title="Reset isian"
                  >
                    <i class="fas fa-sync-alt mr-2"></i> Reset
                  </button>
                  <a
                    href="#"
                    class="btn btn-light ml-1"
                    @click="batal"
                    title="Batal"
                    ><i class="fas fa-times mr-2"></i> Batal</a
                  >
                </div>
                <!-- .col-* -->
              </div>
              <!-- .row -->
            </Form>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
      </div>
      <!-- main-content -->
    </div>
    <!-- .row -->
  </div>
  <!-- .container -->
</template>
<script>
import { useVueSweetAlert2 } from "../../../useVueSweetAlert2.js";
import { useRoute, useRouter } from "vue-router";
import { Field, Form } from "vee-validate";
import { reactive, onMounted } from "vue";
import * as Yup from "yup";
import axios from "axios";
import Notif from "../../../helpers/Notif.vue";
import { useStore } from "vuex";

export default {
  setup() {
    const $swal = useVueSweetAlert2();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      form: {
        name: "",
        desc: "",
      },
      loading: false,
    });
    const batal = () => {
      $swal
        .fire({
          text: "Anda yakin meninggalkan form isian ini?  Perubahan tidak akan disimpan.",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.isConfirmed) {
            router.push({ path: "/jual/pelanggan/group" });
          }
        });
    };
    const edit = () => {
      state.loading = true;
      let id = route.params.id;
      axios.put("/api/seller/pelanggan-group/"+id, state.form).then(
        (res) => {
          if (res.data.success) {
            state.loading = false;
            $swal.fire({
              icon: "success",
              title: "Selamat",
              text: "Update Group Pelanggan Berhasil!",
            });
            router.push({ path: "/jual/pelanggan/group" });
          }
        },
        () => {
          state.loading = false;
        }
      );
    };
    //start validate
    const schema = Yup.object().shape({
      name: Yup.string().required("Nama tidak boleh kosong"),
      desc: Yup.string().required("Deskripsi tidak boleh kosong"),
    });
    const loadData = async () => {
      let id = route.params.id;
      await axios.get("/api/seller/pelanggan-group/"+id).then((res) => {
        if(res.data.success){
          state.form.name = res.data.detail.name;
          state.form.desc = res.data.detail.desc;
        }
      })
    }
    onMounted(() => {
      loadData();
      store.dispatch("error/setDefault");
    })
    const back = () => {
      $swal.fire({
          text: "Anda yakin meninggalkan halaman ini saat proses belum selesai & data belum tersimpan?",
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
      }).then((result) => {
          if (result.isConfirmed) {
            window.history.back();
          }
      })
    }
    return {
      batal,
      state,
      schema,
      edit,
      back,
    };
  },
  components: {
    Field,
    Form,
    Notif,
  },
};
</script>